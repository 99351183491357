import React from 'react';

const Services = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Our Services</h1>
      <div style={styles.service}>
        <h2 style={styles.subHeader}>Airport Transfers</h2>
        <p style={styles.paragraph}>
          We provide reliable and punctual airport transfer services, ensuring you reach your destination comfortably and on time. Our drivers monitor flight schedules to adjust pickup times accordingly.
        </p>
      </div>
      <div style={styles.service}>
        <h2 style={styles.subHeader}>Corporate Travel</h2>
        <p style={styles.paragraph}>
          Our corporate travel services cater to the needs of business professionals, offering a range of luxury vehicles to ensure a smooth and efficient travel experience. We understand the importance of punctuality and professionalism.
        </p>
      </div>
      <div style={styles.service}>
        <h2 style={styles.subHeader}>City Tours</h2>
        <p style={styles.paragraph}>
          Explore the city with our guided tours. Our knowledgeable drivers will take you to the best attractions and hidden gems, providing insights and recommendations along the way.
        </p>
      </div>
      <div style={styles.service}>
        <h2 style={styles.subHeader}>Long-Distance Travel</h2>
        <p style={styles.paragraph}>
          For long-distance journeys, we offer comfortable and spacious vehicles equipped with modern amenities to make your travel enjoyable. Sit back, relax, and let us handle the driving.
        </p>
      </div>
      <div style={styles.service}>
        <h2 style={styles.subHeader}>Special Events</h2>
        <p style={styles.paragraph}>
          Whether it's a wedding, a party, or any special occasion, our event transportation services ensure you arrive in style. We offer customized packages to meet your specific needs and preferences.
        </p>
      </div>
      <style>
        {`
          @media (max-width: 768px) {
            .container {
              padding: 1rem;
              margin-top: 4rem;
            }
            .header {
              font-size: 2rem;
            }
            .subHeader {
              font-size: 1.5rem;
            }
            .paragraph {
              font-size: 1rem;
            }
          }
        `}
      </style>
    </div>
  );
};

const styles = {
  container: {
    marginTop: '8rem',
    padding: '2rem',
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontSize: '2.5rem',
    marginBottom: '2rem',
    color: '#333',
  },
  subHeader: {
    fontSize: '2rem',
    marginBottom: '1rem',
    color: '#555',
  },
  paragraph: {
    fontSize: '1.2rem',
    lineHeight: '1.6',
    color: '#666',
    marginBottom: '1.5rem',
  },
  service: {
    marginBottom: '3rem',
  },
};

export default Services;
