import React from 'react';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.content}>
        <h3 style={styles.heading}>Company</h3>
        <p style={styles.paragraph}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero et diam elementum, a ultricies purus molestie.
        </p>
      </div>
      <div style={styles.bottom}>
        <p>&copy; 2024 Your Company. All rights reserved.</p>
        <p>
          <a href="/privacy-policy" style={styles.link}>Privacy Policy</a> | 
          <a href="/terms-of-service" style={styles.link}> Terms of Service</a>
        </p>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    padding: '20px', // Reduced padding for all sides
    marginTop: '-1.3rem',
  },
  content: {
    marginBottom: '20px', // Reduced margin for smaller screens
  },
  heading: {
    fontSize: '1.5rem', // Adjusted font size for heading
    marginBottom: '10px', // Reduced margin for smaller screens
  },
  paragraph: {
    fontSize: '1rem', // Adjusted font size for paragraph
    lineHeight: '1.5',
  },
  bottom: {
    fontSize: '0.8rem', // Adjusted font size for bottom text
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    marginLeft: '5px',
    marginRight: '5px',
  },
};

export default Footer;
