import React from 'react';

const About = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>About Us</h1>
      <p style={styles.paragraph}>
        We are the leading car travel service provider, dedicated to offering the best travel experiences for our clients. Our team of experienced professionals is here to help you plan the perfect journey, ensuring comfort, safety, and satisfaction.
      </p>
      <p style={styles.paragraph}>
        Our fleet consists of a wide range of vehicles to suit all your travel needs, from luxury sedans and SUVs to spacious vans and buses. Each vehicle is meticulously maintained to provide a smooth and enjoyable ride.
      </p>
      <p style={styles.paragraph}>
        With a strong focus on customer service, our drivers are not only highly skilled but also courteous and knowledgeable about the best routes and local attractions. We prioritize your safety and comfort, making sure you have a pleasant and stress-free travel experience.
      </p>
      <p style={styles.paragraph}>
        Whether you need transportation for a business trip, airport transfer, city tour, or a long-distance journey, we have the perfect solution for you. We offer competitive pricing and customizable travel packages to meet your specific needs and budget.
      </p>
      <h2 style={styles.subHeader}>Our Commitment to Customer Care</h2>
      <p style={styles.paragraph}>
        At our core, we believe that our customers deserve the best. We go above and beyond to ensure that every interaction is seamless and pleasant. From the moment you book your trip to the final drop-off, we strive to exceed your expectations.
      </p>
      <p style={styles.paragraph}>
        Our customer support team is available 24/7 to assist you with any inquiries or special requests. We listen to your feedback and continuously improve our services based on your suggestions. Your satisfaction is our top priority, and we are committed to making every journey a memorable one.
      </p>
      <p style={styles.paragraph}>
        Contact us today to learn more about our services and how we can help you create unforgettable travel memories. We look forward to serving you and making your travel experience exceptional.
      </p>
      <style>
        {`
          @media (max-width: 768px) {
            .container {
              padding: 1rem;
              margin-top: 4rem;
            }
            .header {
              font-size: 2rem;
            }
            .subHeader {
              font-size: 1.5rem;
            }
            .paragraph {
              font-size: 1rem;
            }
          }
        `}
      </style>
    </div>
  );
};

const styles = {
  container: {
    marginTop: '8rem',
    padding: '2rem',
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontSize: '2.5rem',
    marginBottom: '1rem',
    color: '#333',
  },
  subHeader: {
    fontSize: '2rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    color: '#555',
  },
  paragraph: {
    fontSize: '1.2rem',
    lineHeight: '1.6',
    color: '#666',
    marginBottom: '1rem',
  },
};

export default About;
