import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const TopHeader = () => {
  return (
    <div className="top-header">
      <style>
        {`
          .top-header {
            background-color: #333;
            color: #fff;
            padding: 10px 0;
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 1000;
          }
          .content-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px; /* Adjusted padding for smaller screens */
          }
          .social-media a {
            color: orange;
            margin-right: 10px;
            font-size: 18px; /* Smaller font size for smaller screens */
          }
          .social-media a:hover {
            color: #ddd;
          }
          .contact-info {
            display: flex;
            align-items: center;
          }
          .contact-info span {
            margin-left: 5px;
          }
          .contact-item {
            display: flex;
            align-items: center;
            margin-left: 10px; /* Adjusted margin for smaller screens */
            color: orange;
          }
          .contact-item span {
            color: white;
          }
          .contact-item a {
            color: white;
            text-decoration: none;
            margin-left: 5px;
          }
          .contact-item a:hover {
            text-decoration: underline;
          }
        `}
      </style>
      <div className="content-container">
        <div className="social-media">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>
        <div className="contact-info">
          <div className="contact-item">
            <FontAwesomeIcon icon={faEnvelope} />
            <a href="mailto:example@mail.com">example@mail.com</a>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faPhone} />
            <a href="tel:+1234567890">+123-456-7890</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
