// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import HomePage from './HomePage';
import About from './About';
import Services from './Services';
import Tours from './Tours';
import ContactPage from './ContactPage';
import Preloader from './Preloader';
import TopHeader from './TopHeader';

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Router>
      <div>
      
        {!isLoaded && <Preloader onLoaded={() => setIsLoaded(true)} />}
        {isLoaded && (
          <><TopHeader />
            <Header />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/About" element={<About />} />
              <Route path="/Services" element={<Services />} />
              <Route path="/Tours" element={<Tours />} />
              <Route path="/Contact" element={<ContactPage />} />
            </Routes>
            <Footer />
          </>
        )}
      </div>
    </Router>
  );
};

export default App;
