import React from 'react';

const ContactPage = () => {
  const handleContactClick = () => {
    alert('You clicked Contact Us');
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:info@example.com';
  };

  const handleAddressClick = () => {
    alert('123 Street, City, Country');
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.header}>Contact Us</h1>
        <p style={styles.info}>
          Phone: <a href="tel:+1234567890">+1234567890</a>,{' '}
          <a href="tel:+9876543210">+9876543210</a>
        </p>
        <p style={styles.info}>
          Email: <a href="mailto:info@example.com">info@example.com</a>
        </p>
        <p style={styles.info}>Address: 123 Street, City, Country</p>
      </div>
      <div style={styles.contactBox} onClick={handleContactClick}>
        <p style={styles.boxText}>Contact Us</p>
      </div>
      <div style={styles.contactBox} onClick={handleEmailClick}>
        <p style={styles.boxText}>Email: info@example.com</p>
      </div>
      <div style={styles.contactBox} onClick={handleAddressClick}>
        <p style={styles.boxText}>Address: 123 Street, City, Country</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginTop: '100px', // Adjust as needed to clear the fixed header
    backgroundColor: '#f5f5f5',
    color: '#333',
    textAlign: 'center',
    padding: '2rem',
  },
  content: {
    marginBottom: '2rem',
  },
  header: {
    fontSize: '2.5rem',
    marginBottom: '1rem',
  },
  info: {
    fontSize: '1.2rem',
    marginBottom: '0.5rem',
  },
  contactBox: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '1.5rem',
    borderRadius: '8px',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '1rem',
    transition: 'background-color 0.3s ease',
  },
  boxText: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
};

export default ContactPage;
