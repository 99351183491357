import React, { useEffect, useState } from 'react';

const Preloader = ({ onLoaded, timeout = 5000 }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(null);

  useEffect(() => {
    console.log('Video load state:', isVideoLoaded);
    if (isVideoLoaded) {
      const timer = setTimeout(() => {
        onLoaded();
      }, timeout); // Duration of the video in milliseconds

      return () => clearTimeout(timer);
    }
  }, [isVideoLoaded, onLoaded, timeout]);

  const styles = {
    preloader: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      zIndex: 9999,
    },
    video: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    error: {
      position: 'absolute',
      bottom: '20px',
      background: 'rgba(255, 0, 0, 0.8)',
      color: 'white',
      padding: '10px',
      borderRadius: '5px',
      textAlign: 'center',
      maxWidth: '90%',
      fontSize: '16px',
    },
    errorMobile: {
      bottom: '10px',
      fontSize: '14px',
      padding: '8px',
    },
    errorSmallMobile: {
      fontSize: '12px',
      padding: '6px',
    }
  };

  return (
    <div style={styles.preloader}>
      <video
        autoPlay
        muted
        onLoadedData={() => {
          console.log("Video loaded successfully");
          setIsVideoLoaded(true);
        }}
        onError={(e) => {
          console.error("Error loading video", e);
          setLoadingError("Failed to load the video.");
        }}
        style={styles.video}
      >
        <source src="/car_starting3.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {loadingError && (
        <div
          style={{
            ...styles.error,
            ...(window.innerWidth <= 768 ? styles.errorMobile : {}),
            ...(window.innerWidth <= 480 ? styles.errorSmallMobile : {}),
          }}
          aria-live="assertive"
        >
          {loadingError}
        </div>
      )}
    </div>
  );
};

export default Preloader;
