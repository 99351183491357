import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Header = () => {
  return (
    <motion.header
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        background: 'white',
        color: '#000',
        padding: '1rem',
        marginBottom: '1rem',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        position: 'fixed',
        width: '100%',
        top: '40px',
        zIndex: 999,
      }}
    >
      <nav style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        margin: '0 15px', /* Adjusted margin for smaller screens */
      }}>
        <motion.div
          className="logo"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: '0 15px', color: '#000' }}
        >
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            Travel Agency
          </Link>
        </motion.div>
        <ul style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
          {['Home', 'About', 'Services', 'Tours', 'Contact'].map((item) => (
            <motion.li
              key={item}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              style={{ margin: '0 10px', fontSize: '14px' }} /* Adjusted margin and font size for smaller screens */
            >
              <Link to={item === 'Home' ? '/' : `/${item.toLowerCase()}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                {item}
              </Link>
            </motion.li>
          ))}
        </ul>
      </nav>
    </motion.header>
  );
};

export default Header;
