import React from 'react';

const Tours = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Our Tours</h1>
      <div style={styles.tour}>
        <h2 style={styles.subHeader}>City Highlights Tour</h2>
        <p style={styles.paragraph}>
          Discover the best that the city has to offer with our City Highlights Tour. Visit famous landmarks, historic sites, and popular attractions while learning interesting facts and stories from our knowledgeable guides.
        </p>
      </div>
      <div style={styles.tour}>
        <h2 style={styles.subHeader}>Cultural Heritage Tour</h2>
        <p style={styles.paragraph}>
          Immerse yourself in the local culture with our Cultural Heritage Tour. Explore museums, art galleries, and cultural centers to gain a deeper understanding of the city's rich history and traditions.
        </p>
      </div>
      <div style={styles.tour}>
        <h2 style={styles.subHeader}>Nature and Adventure Tour</h2>
        <p style={styles.paragraph}>
          For those seeking adventure, our Nature and Adventure Tour is perfect for you. Enjoy activities such as hiking, biking, and wildlife spotting in some of the most beautiful natural settings around the city.
        </p>
      </div>
      <div style={styles.tour}>
        <h2 style={styles.subHeader}>Food and Wine Tour</h2>
        <p style={styles.paragraph}>
          Experience the culinary delights of the city with our Food and Wine Tour. Visit top-rated restaurants, local markets, and vineyards to taste delicious food and exquisite wines.
        </p>
      </div>
      <div style={styles.tour}>
        <h2 style={styles.subHeader}>Customized Private Tour</h2>
        <p style={styles.paragraph}>
          Create your own adventure with our Customized Private Tour. Whether you have specific destinations in mind or want our experts to design a unique itinerary for you, we cater to your preferences for a personalized experience.
        </p>
      </div>
      <style>
        {`
          @media (max-width: 768px) {
            .container {
              padding: 1rem;
              margin-top: 4rem;
            }
            .header {
              font-size: 2rem;
            }
            .subHeader {
              font-size: 1.5rem;
            }
            .paragraph {
              font-size: 1rem;
            }
          }
        `}
      </style>
    </div>
  );
};

const styles = {
  container: {
    marginTop: '8rem',
    padding: '2rem',
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontSize: '2.5rem',
    marginBottom: '2rem',
    color: '#333',
  },
  subHeader: {
    fontSize: '2rem',
    marginBottom: '1rem',
    color: '#555',
  },
  paragraph: {
    fontSize: '1.2rem',
    lineHeight: '1.6',
    color: '#666',
    marginBottom: '1.5rem',
  },
  tour: {
    marginBottom: '3rem',
  },
};

export default Tours;
