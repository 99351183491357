import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const HomePage = () => {
  return (
    <div style={styles.container}>
      <section className="carousel-section">
        <Carousel showThumbs={false} autoPlay interval={5000} infiniteLoop showStatus={false}>
          <div>
            <img src={process.env.PUBLIC_URL + '/car3.jpg'} alt="Car Travel 1" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + '/car2.jpg'} alt="Car Travel 2" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + '/car1.jpg'} alt="Car Travel 3" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        </Carousel>
      </section>
    </div>
  );
};

const styles = {
  container: {
    marginTop: '100px', // Adjusted margin to give space for the fixed header
    backgroundColor: '#fff',
    color: '#000',
    textAlign: 'center',
    padding: '2rem',
  },
};

export default HomePage;
